import React from "react";
import { Box, Button } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

import { PageTitle } from "../../components/page-title";
import TimesheetActivityDetails from "../../features/timesheet/components/timesheet-activity-details";
import TimesheetTime from "../../features/timesheet/components/timesheet-time";
import TimesheetCharge from "../../features/timesheet/components/timesheet-charge";
import TimesheetOther from "../../features/timesheet/components/timesheet-other";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import {
  Contact,
  useListOfContacts,
} from "../../features/accounting/api/list-of-contacts";
import { useActivityTypes } from "../../features/timesheet/api/timesheet-get-activity-types";
import {
  TimesheetInsertPayload,
  useTimesheetInsert,
} from "../../features/timesheet/api/timesheet-insert-timesheet";
import { getCookie } from "../../utils/api";
import { useTimesheetDailyList } from "../../features/timesheet/api/timesheet-get-daily-list";
import TimesheetList from "../../features/timesheet/components/timesheet-list";
import { useTimesheetDelete } from "../../features/timesheet/api/timesheet-delete-timesheet";
import { useTimesheetDetails } from "../../features/timesheet/api/timesheet-get-details";
import { calculateTimeUnits } from "../../utils/time";

export type TimesheetErrors = {
  client: string;
  user: string;
  activityType: string;
  description: string;
  date: string;
  startTime: string;
  endTime: string;
};

export default function TimesheetDetailed() {
  const { enqueueSnackbar } = useSnackbar();

  // Activity Details state
  const [client, setClient] = React.useState<Contact | null>(null);
  const [user, setUser] = React.useState<any>({
    usrID: getCookie("globalUserId"),
    usrName: "",
  });
  const [department, setDepartment] = React.useState<any>(null);
  const [activityType, setActivityType] = React.useState<any>(null);
  const [description, setDescription] = React.useState("");
  const [task, setTask] = React.useState<any>(null);

  // Time state
  const [date, setDate] = React.useState<dayjs.Dayjs | null>(dayjs());
  const [startTime, setStartTime] = React.useState<dayjs.Dayjs | null>(null);
  const [endTime, setEndTime] = React.useState<dayjs.Dayjs | null>(null);
  const [timeUnits, setTimeUnits] = React.useState(0);

  // Charge state
  const [isChargeable, setIsChargeable] = React.useState(true);
  const [charge, setCharge] = React.useState<number>(0);
  const [hourlyRate, setHourlyRate] = React.useState<number>(0);

  // Other state
  const [markForAttention, setMarkForAttention] = React.useState(false);
  const [note, setNote] = React.useState("");

  const [errors, setErrors] = React.useState<TimesheetErrors>({
    client: "",
    user: "",
    activityType: "",
    description: "",
    date: "",
    startTime: "",
    endTime: "",
  });

  // Queries
  const { data: contactsData, isLoading: isLoadingContacts } =
    useListOfContacts();

  const timesheetDetailsQuery = useTimesheetDetails({
    onSuccess: (data) => {
      const userFromDetails = data.ListOfLocalUsers.find(
        (user) => data.CurrentLocalUserId === user.usrID,
      );

      if (userFromDetails) {
        setUser(userFromDetails);
      }
    },
  });
  const dailyListQuery = useTimesheetDailyList({ date });

  const { insertTimesheet, isLoading: isLoadingInsert } = useTimesheetInsert({
    onSuccess: () => {
      enqueueSnackbar("Timesheet saved successfully", { variant: "success" });
      dailyListQuery.refetch();
    },
  });

  const { deleteTimesheet, isLoading: isLoadingDelete } = useTimesheetDelete({
    onSuccess: () => {
      dailyListQuery.refetch();
    },
  });

  // Handlers
  const handleClientChange = (value: Contact | null) => {
    setClient(value);
    setErrors((prev) => ({ ...prev, client: "" }));
  };
  const handleUserChange = (value: any) => {
    setUser(value);
    setErrors((prev) => ({ ...prev, user: "" }));
  };
  const handleDepartmentChange = (value: any) => setDepartment(value);

  const handleActivityTypeChange = (value: any) => {
    setActivityType(value);
    setErrors((prev) => ({ ...prev, activityType: "" }));
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    setErrors((prev) => ({ ...prev, description: "" }));
  };

  const handleTaskChange = (value: any) => setTask(value);

  const handleDateChange = (value: dayjs.Dayjs | null) => {
    setDate(value);
    setErrors((prev) => ({ ...prev, date: "" }));
  };

  const handleStartTimeChange = (value: dayjs.Dayjs | null) => {
    setStartTime(value);
    setErrors((prev) => ({ ...prev, startTime: "" }));
  };

  const handleEndTimeChange = (value: dayjs.Dayjs | null) => {
    setEndTime(value);
    setErrors((prev) => ({ ...prev, endTime: "" }));
  };

  const handleChargeableChange = (value: boolean) => setIsChargeable(value);
  const handleChargeChange = (value: number) => setCharge(value);
  const handleHourlyRateChange = (value: number) => setHourlyRate(value);

  const handleMarkForAttentionChange = (value: boolean) =>
    setMarkForAttention(value);
  const handleNoteChange = (value: string) => setNote(value);

  const clearForm = () => {
    setClient(null);
    // setUser(null);
    setDepartment(null);
    setActivityType(null);
    setDescription("");
    setTask(null);
    setDate(dayjs());
    setStartTime(null);
    setEndTime(null);
    setTimeUnits(0);
    setIsChargeable(true);
    setCharge(0);
    setHourlyRate(0);
    setMarkForAttention(false);
    setNote("");
    setErrors({
      client: "",
      user: "",
      activityType: "",
      description: "",
      date: "",
      startTime: "",
      endTime: "",
    });
  };

  // Validation
  const validateForm = () => {
    const newErrors = {
      client: !client ? "Client is required" : "",
      user: !user ? "User is required" : "",
      activityType: !activityType ? "Activity type is required" : "",
      description: !description.trim() ? "Description is required" : "",
      date: !date ? "Date is required" : "",
      startTime: !startTime ? "Start time is required" : "",
      endTime: !endTime ? "End time is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const payloadDateTime =
        date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";
      const payloadStartTime =
        startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";
      const payloadEndTime =
        endTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      console.log("StartTime: ", payloadStartTime);
      console.log("EndTime: ", payloadEndTime);
      console.log("DateTime: ", payloadDateTime);

      const payload: TimesheetInsertPayload = {
        acvType_avtID: activityType?.avtID,
        acvUser_usrID: user?.usrID || 0,
        acvTimeUnits: timeUnits,
        acvDescription: description,
        acvNotes: note,
        acvTaskDate: payloadDateTime,
        acvStartTime: payloadStartTime,
        acvEndTime: payloadEndTime,
        acvInvoice_invID: 0,
        acvChargeableYN: isChargeable ? "Y" : "N",
        acvContact_cntID: client?.cntId || 0,
        acvHourlyRate: hourlyRate,
        acvManualUnitsYN: "N",
        acvCharge: charge,
        acvOurCost: 0,
        acvTask_prdID: task?.prdID || 0,
        acvSite_dpsID: department?.dpsID || 0,
        acvPayroll_pevID: 0,
        acvPaidBreakUnits: 0,
        acvUnpaidBreakUnits: 0,
        acvDocument_docID: "",
        acvTransferGUID: "",
        acvEntrySource: "",
        acvForActionYN: markForAttention ? "Y" : "N",
        acvInvReadyYN: "N",
      };

      try {
        await insertTimesheet(payload);
      } catch (error) {
        console.error("Failed to save timesheet:", error);
        enqueueSnackbar("Failed to save timesheet", { variant: "error" });
      }
    }
  };

  const handleSaveAndNew = async () => {
    if (validateForm()) {
      const payloadDateTime =
        date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";
      const payloadStartTime =
        startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";
      const payloadEndTime =
        endTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      console.log("StartTime: ", payloadStartTime);
      console.log("EndTime: ", payloadEndTime);
      console.log("DateTime: ", payloadDateTime);

      const payload: TimesheetInsertPayload = {
        acvType_avtID: activityType?.avtID,
        acvUser_usrID: user?.usrID || 0,
        acvTimeUnits: timeUnits,
        acvDescription: description,
        acvNotes: note,
        acvTaskDate: payloadDateTime,
        acvStartTime: payloadStartTime,
        acvEndTime: payloadEndTime,
        acvInvoice_invID: 0,
        acvChargeableYN: isChargeable ? "Y" : "N",
        acvContact_cntID: client?.cntId || 0,
        acvHourlyRate: hourlyRate,
        acvManualUnitsYN: "N",
        acvCharge: charge,
        acvOurCost: 0,
        acvTask_prdID: task?.prdID || 0,
        acvSite_dpsID: department?.dpsID || 0,
        acvPayroll_pevID: 0,
        acvPaidBreakUnits: 0,
        acvUnpaidBreakUnits: 0,
        acvDocument_docID: "",
        acvTransferGUID: "",
        acvEntrySource: "",
        acvForActionYN: markForAttention ? "Y" : "N",
        acvInvReadyYN: "N",
      };

      try {
        await insertTimesheet(payload);
        clearForm();
      } catch (error) {
        console.error("Failed to save timesheet:", error);
        enqueueSnackbar("Failed to save timesheet", { variant: "error" });
      }
    }
  };

  const handleSaveAndCreateInvoice = async () => {
    if (validateForm()) {
      const payloadDateTime =
        date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";
      const payloadStartTime =
        startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";
      const payloadEndTime =
        endTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      const payload: TimesheetInsertPayload = {
        acvType_avtID: activityType?.avtID,
        acvUser_usrID: user?.usrID || 0,
        acvTimeUnits: timeUnits,
        acvDescription: description,
        acvNotes: note,
        acvTaskDate: payloadDateTime,
        acvStartTime: payloadStartTime,
        acvEndTime: payloadEndTime,
        acvInvoice_invID: 0,
        acvChargeableYN: isChargeable ? "Y" : "N",
        acvContact_cntID: client?.cntId || 0,
        acvHourlyRate: hourlyRate,
        acvManualUnitsYN: "N",
        acvCharge: charge,
        acvOurCost: 0,
        acvTask_prdID: task?.prdID || 0,
        acvSite_dpsID: department?.dpsID || 0,
        acvPayroll_pevID: 0,
        acvPaidBreakUnits: 0,
        acvUnpaidBreakUnits: 0,
        acvDocument_docID: "",
        acvTransferGUID: "",
        acvEntrySource: "",
        acvForActionYN: markForAttention ? "Y" : "N",
        acvInvReadyYN: "Y",
      };

      try {
        await insertTimesheet(payload);
        clearForm();
      } catch (error) {
        console.error("Failed to save timesheet:", error);
        enqueueSnackbar("Failed to save timesheet", { variant: "error" });
      }
    }
  };

  const handleDeleteTimesheet = React.useCallback(
    (id: string) => {
      deleteTimesheet(id);
    },
    [deleteTimesheet],
  );

  const getActivityTypeNameById = React.useCallback(
    (id: number) => {
      const activityType = timesheetDetailsQuery.data?.ListOfActivityTypes.find(
        (type) => type.avtID === id,
      );
      return activityType?.avtName || `Activity Type id: ${id}`;
    },
    [timesheetDetailsQuery.data],
  );

  // Time calculations
  React.useEffect(() => {
    if (startTime && endTime) {
      const diffInMinutes = endTime.diff(startTime, "minute");
      if (diffInMinutes > 0) {
        setTimeUnits(diffInMinutes / 60);
      }
    }
  }, [startTime, endTime]);

  const contacts = contactsData?.ListOfContacts || [];
  // const departments = sitesData?.ListOfSites || [];
  const departments = timesheetDetailsQuery.data?.ListOfSites || [];
  const activityTypes = timesheetDetailsQuery.data?.ListOfActivityTypes || [];
  const users = timesheetDetailsQuery.data?.ListOfLocalUsers || [];
  const tasks = [];

  const isLoading =
    isLoadingContacts ||
    timesheetDetailsQuery.isLoading ||
    isLoadingInsert ||
    isLoadingDelete;

  return (
    <Box>
      <BackdropLoading open={isLoading} />
      <PageTitle title="New Timesheet" />
      <Box display="flex" flexDirection="column" gap={4} mt={4}>
        <TimesheetActivityDetails
          client={client}
          onClientChange={handleClientChange}
          user={user}
          onUserChange={handleUserChange}
          department={department}
          onDepartmentChange={handleDepartmentChange}
          activityType={activityType}
          onActivityTypeChange={handleActivityTypeChange}
          description={description}
          onDescriptionChange={handleDescriptionChange}
          task={task}
          onTaskChange={handleTaskChange}
          contacts={contacts}
          activityTypes={activityTypes}
          users={users}
          departments={departments}
          tasks={tasks}
          errors={errors}
        />
        <TimesheetTime
          date={date}
          onDateChange={handleDateChange}
          startTime={startTime}
          onStartTimeChange={handleStartTimeChange}
          endTime={endTime}
          onEndTimeChange={handleEndTimeChange}
          timeUnits={timeUnits}
          errors={errors}
        />
        <TimesheetCharge
          isChargeable={isChargeable}
          onChargeableChange={handleChargeableChange}
          charge={charge}
          onChargeChange={handleChargeChange}
          hourlyRate={hourlyRate}
          onHourlyRateChange={handleHourlyRateChange}
        />
        <TimesheetOther
          markForAttention={markForAttention}
          onMarkForAttentionChange={handleMarkForAttentionChange}
          note={note}
          onNoteChange={handleNoteChange}
        />
        <Box
          display="flex"
          gap={2}
          flexDirection={{ xs: "column", md: "row" }}
          mt={2}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ width: { xs: "100%", md: "auto" } }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAndNew}
            sx={{ width: { xs: "100%", md: "auto" } }}
          >
            Save New
          </Button>
          <Button
            variant="outlined"
            onClick={handleSaveAndCreateInvoice}
            sx={{ width: { xs: "100%", md: "auto" } }}
          >
            Save & Create Invoice
          </Button>
          <Button
            variant="outlined"
            onClick={clearForm}
            sx={{ width: { xs: "100%", md: "auto" } }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      {dailyListQuery.data && (
        <Box marginY={8}>
          <TimesheetList
            timesheetList={dailyListQuery.data.ListOfDailyTimesheet}
            onDeleteTimesheet={handleDeleteTimesheet}
            getActivityTypeNameById={getActivityTypeNameById}
          />
        </Box>
      )}
    </Box>
  );
}
