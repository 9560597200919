import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { List, ListRowProps } from "react-virtualized";

interface ListboxComponentProps {
  children: React.ReactNode;
  role?: string;
  [key: string]: any;
}

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  ListboxComponentProps
>(function ListboxComponent(props, ref) {
  const { children, role, width, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 48;

  // Add ref to outer container to calculate actual width
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [calculatedWidth, setCalculatedWidth] = React.useState(0);

  React.useEffect(() => {
    if (containerRef.current) {
      setCalculatedWidth(containerRef.current.offsetWidth);
    }
  }, [width]);

  return (
    <div ref={ref}>
      <div {...other} ref={containerRef}>
        <List
          height={250}
          // width={width}
          width={typeof width === "number" ? width : calculatedWidth}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(listRowProps: ListRowProps) => {
            if (!children) {
              return null;
            }
            return React.cloneElement(children[listRowProps.index], {
              style: listRowProps.style,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

interface VirtualizedAutocompleteProps {
  options: any[];
  getOptionLabel: (option: any) => string;
  onChange: (event: any, value: any) => void;
  value?: any;
  label?: string;
  width?: number | "100%";
  otherAutoCompleteProps?: any;
  error?: boolean;
  helperText?: string;
}

export function VirtualizedAutocomplete({
  options,
  getOptionLabel,
  onChange,
  value,
  label,
  width,
  otherAutoCompleteProps = {},
  error,
  helperText,
}: VirtualizedAutocompleteProps) {
  return (
    <Autocomplete
      id="virtualize-demo"
      style={{ width: width }}
      disableListWrap
      ListboxComponent={ListboxComponent}
      ListboxProps={{ width: "100%" }}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          fullWidth
          error={!!error}
          helperText={helperText}
        />
      )}
      {...otherAutoCompleteProps}
    />
  );
}
