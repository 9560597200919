import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import { usePayrollDashboardData } from "../../features/payroll/api/get-dashboard-data";
import { PayrollDashboardEventsListGrid } from "../../features/payroll/components/PayrollDashboardEventsListGrid";
import { PayrollDashboardIssueListGrid } from "../../features/payroll/components/PayrollDashboardIssueListGrid";
import { PayrollConfigurationMenu } from "../../features/payroll/components/PayrollConfigurationMenu";
import { useNavigateInternal } from "../../hooks/use-navigate-internal";

export default function PayrollDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const { navigateTo, openLegacyInNewTab } = useNavigateInternal();

  const payrollDashboardQuery = usePayrollDashboardData();

  const handleIssueRowDoubleClick = (
    detsCode: string,
    params?: Record<string, any>,
  ) => {
    console.log("detsCode", detsCode);
    console.log("params", params);
  };

  const handleNewPayEvent = () => {
    navigateTo("/payroll/new-pay-event");
  };

  const handleSeeAllPayEvents = () => {
    enqueueSnackbar("Not implemented yet!", { variant: "info" });
  };

  const handleTrialBalance = () => {
    navigateTo("/reports/payroll-trial-balance");
  };

  console.log("payrollDashboardQuery", payrollDashboardQuery);
  return (
    <>
      <BackdropLoading open={false} />
      <PageTitle title="Your Payroll Dashboard" />
      <Box display="flex" gap={8} mt={4}>
        <Box flex={1} display="flex" flexDirection="column" gap={4}>
          <Box>
            <Typography variant="subtitle1" mb={1}>
              Pay events - recent and for attention
            </Typography>
            <PayrollDashboardEventsListGrid
              data={payrollDashboardQuery.data?.EventsList.DataDT || []}
              columns={payrollDashboardQuery.data?.EventsList.ColumnsList || []}
              tooltips={payrollDashboardQuery.data?.EventsList.TooltipsList}
              actionsList={payrollDashboardQuery.data?.EventsList.ActionsList}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Button variant="outlined" onClick={handleNewPayEvent}>
              New Pay Event
            </Button>
            <Button variant="outlined" onClick={handleSeeAllPayEvents}>
              See all pay events
            </Button>
            <Button variant="outlined" onClick={handleTrialBalance}>
              Trial Balance
            </Button>
          </Box>
          <Box
            dangerouslySetInnerHTML={{
              __html: payrollDashboardQuery.data?.NextPayEvents || "",
            }}
          />
          <Box display="flex" gap={2}>
            <PayrollConfigurationMenu />
          </Box>
        </Box>
        <Box flex={1}>
          <Box>
            <Typography variant="subtitle1" mb={1}>
              Select issue to see details
            </Typography>
            <PayrollDashboardIssueListGrid
              data={payrollDashboardQuery.data?.IssuesList.DataDT || []}
              columns={payrollDashboardQuery.data?.IssuesList.ColumnsList || []}
              tooltips={payrollDashboardQuery.data?.IssuesList.TooltipsList}
              actionsList={payrollDashboardQuery.data?.IssuesList.ActionsList}
              onRowDoubleClick={handleIssueRowDoubleClick}
            />
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </>
  );
}
