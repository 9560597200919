import React from "react";
import dayjs from "dayjs";

import { Box, Grid, Autocomplete, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberField from "../../../components/ui/inputs/number-input";
import { formatDateToISO } from "../../../utils/format-date";

interface PaymentSummaryDetailsFormTopProps {
  client: any;
  selectedDate: Date | null;
  amount: number;
  dateError?: string | null;
  clientError?: string | null;
  onDateChange: (date: Date | null) => void;
}

export function PaymentSummaryDetailsFormTop({
  client,
  selectedDate,
  amount,
  dateError,
  clientError,
  onDateChange,
}: PaymentSummaryDetailsFormTopProps) {
  return (
    <Box>
      <Grid container item spacing={3.5}>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={[]}
            value={client || null}
            getOptionLabel={(option) => `${option.cntName}`}
            disabled={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client"
                error={!!clientError}
                helperText={clientError ?? ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Date"
              value={selectedDate ? dayjs(formatDateToISO(selectedDate)) : null}
              defaultValue={undefined}
              onChange={(newValue) =>
                onDateChange(newValue ? newValue.toDate() : null)
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!dateError,
                  helperText: dateError ?? "",
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: dateError ? "error" : "inherit",
                      },
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <NumberField
            fullWidth
            label="Amount"
            value={amount}
            onChange={() => {}}
            min={0}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
