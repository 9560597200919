import React, { useCallback } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";

import TimesheetHeader from "../../features/timesheet/components/timesheet-header";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import TimesheetActions from "../../features/timesheet/components/timesheet-actions";
import { useActivityTypes } from "../../features/timesheet/api/timesheet-get-activity-types";
import { ActivityType } from "../../features/timesheet/types";
import {
  Contact,
  useListOfContacts,
} from "../../features/accounting/api/list-of-contacts";
import { useTimesheetDailyList } from "../../features/timesheet/api/timesheet-get-daily-list";
import TimesheetList from "../../features/timesheet/components/timesheet-list";
import TimesheetDailyForm from "../../features/timesheet/components/timesheet-daily-form";
import {
  TimesheetInsertPayload,
  useTimesheetInsert,
} from "../../features/timesheet/api/timesheet-insert-timesheet";
import { getCookie } from "../../utils/api";
import { calculateTimeUnits } from "../../utils/time";
import { useTimesheetDelete } from "../../features/timesheet/api/timesheet-delete-timesheet";
import { useTimesheetDetails } from "../../features/timesheet/api/timesheet-get-details";

export default function Timesheet() {
  const [date, setDate] = React.useState<dayjs.Dayjs | null>(dayjs());
  const [description, setDescription] = React.useState("");
  const [client, setClient] = React.useState<Contact | null>(null);
  const [activityType, setActivityType] = React.useState<ActivityType | null>(
    null,
  );
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [startTime, setStartTime] = useState<dayjs.Dayjs | null>(null);
  const [duration, setDuration] = useState<string>("00:00:00");

  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [clientError, setClientError] = useState<string | null>(null);
  const [activityTypeError, setActivityTypeError] = useState<string | null>(
    null,
  );
  const [durationError, setDurationError] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const timesheetDetailsQuery = useTimesheetDetails();
  // const activityTypesQuery = useActivityTypes();
  const contactsQuery = useListOfContacts();
  const dailyListQuery = useTimesheetDailyList({ date });
  const { insertTimesheet, isLoading } = useTimesheetInsert({
    onSuccess: () => {
      resetForm();
      // Refetch timesheet list
      dailyListQuery.refetch();
    },
  });

  const { deleteTimesheet, isLoading: isLoadingDelete } = useTimesheetDelete({
    onSuccess: () => {
      dailyListQuery.refetch();
    },
  });

  const handleActivityTypeChange = (newActivityType: ActivityType) => {
    setActivityType(newActivityType);
    setActivityTypeError(null);
  };

  const handleClientChange = (newClient: Contact) => {
    setClient(newClient);
    setClientError(null);
  };

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    setDescriptionError(null);
  };

  const handleStartTimer = (start: dayjs.Dayjs) => {
    setIsTimerRunning(true);
    setStartTime(start);
    setDurationError(null);
  };

  const handleStopTimer = (time: string) => {
    setIsTimerRunning(false);
    setDuration(time);
    setDurationError(null);
  };

  const resetForm = useCallback(() => {
    // Reset inputs
    setDescription("");
    setClient(null);
    setActivityType(null);
    setIsTimerRunning(false);
    setStartTime(null);
    setDuration("00:00:00");

    // Clear errors
    setDescriptionError(null);
    setClientError(null);
    setActivityTypeError(null);
    setDurationError(null);
  }, []);

  const handleDateChange = useCallback(
    (newDate: dayjs.Dayjs | null) => {
      setDate(newDate);
      resetForm();
    },
    [resetForm],
  );

  const handleNewTimesheet = () => {
    let hasError = false;

    if (!description.trim()) {
      setDescriptionError("Description is required");
      hasError = true;
    }

    if (!client) {
      setClientError("Client is required");
      hasError = true;
    }

    if (!activityType) {
      setActivityTypeError("Activity type is required");
      hasError = true;
    }

    if (duration === "00:00:00") {
      setDurationError("Invalid duration");
      hasError = true;
    }

    if (hasError) {
      enqueueSnackbar("Please fill in all required fields!", {
        variant: "warning",
      });
      return;
    }

    if (
      !activityType ||
      !activityType.avtID ||
      !client ||
      !client?.cntId ||
      !duration
    ) {
      return;
    }

    const userID = timesheetDetailsQuery.data?.CurrentLocalUserId;
    const timeUnits = calculateTimeUnits(duration);
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    const payloadDateTime = date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";
    const payloadStartTime =
      startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";
    const payloadEndTime =
      startTime
        ?.add(hours, "hours")
        .add(minutes, "minutes")
        .add(seconds, "seconds")
        .format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

    console.log("StartTime: ", payloadStartTime);
    console.log("EndTime: ", payloadEndTime);

    const payload: TimesheetInsertPayload = {
      acvType_avtID: activityType?.avtID,
      acvUser_usrID: Number(userID) || 0,
      acvTimeUnits: timeUnits,
      acvDescription: description,
      acvNotes: "",
      acvTaskDate: payloadDateTime,
      acvStartTime: payloadStartTime,
      acvEndTime: payloadEndTime,
      acvInvoice_invID: 0, // TODO: get invoice id
      acvChargeableYN: "Y",
      acvContact_cntID: client?.cntId,
      acvHourlyRate: 0, // TODO: get hourly rate
      acvManualUnitsYN: "N",
      acvCharge: 0,
      acvOurCost: 0,
      acvTask_prdID: 0,
      acvSite_dpsID: 0,
      acvPayroll_pevID: 0,
      acvPaidBreakUnits: 0,
      acvUnpaidBreakUnits: 0,
      acvDocument_docID: "",
      acvTransferGUID: "",
      acvEntrySource: "",
      acvForActionYN: "Y",
      acvInvReadyYN: "N",
    };

    insertTimesheet(payload);
  };

  const handleDeleteTimesheet = useCallback(
    (id: string) => {
      deleteTimesheet(id);
    },
    [deleteTimesheet],
  );

  const getActivityTypeNameById = useCallback(
    (id: number) => {
      const activityType = timesheetDetailsQuery.data?.ListOfActivityTypes.find(
        (type) => type.avtID === id,
      );
      return activityType?.avtName || `Activity Type id: ${id}`;
    },
    [timesheetDetailsQuery.data?.ListOfActivityTypes],
  );

  const contactsOptions = contactsQuery.data?.ListOfContacts || [];
  const activityTypesOptions =
    timesheetDetailsQuery.data?.ListOfActivityTypes || [];

  console.log("--------------------------------");
  console.log("isTimerRunning", isTimerRunning);
  console.log("startTime", startTime);
  console.log("duration", duration);
  console.log("--------------------------------");

  return (
    <>
      <BackdropLoading
        open={
          isLoading ||
          contactsQuery.isLoading ||
          isLoadingDelete ||
          dailyListQuery.isLoading
        }
      />
      <TimesheetHeader
        date={date}
        onChangeDate={handleDateChange}
        totalUnits={100}
        missingUnits={10}
        databaseName="EXACC"
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={{ xs: 0, md: 4 }}
        boxShadow={{
          xs: "none",
          md: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
        borderRadius={2}
        marginY={4}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={2}
        >
          <TimesheetActions
            onClickNewTimesheet={handleNewTimesheet}
            onClickActivityTypeSettings={() => {}}
            disableNewTimesheet={isTimerRunning}
          />
          <TimesheetDailyForm
            activityType={activityType}
            activityTypesOptions={activityTypesOptions}
            onActivityTypeChange={handleActivityTypeChange}
            description={description}
            onDescriptionChange={handleDescriptionChange}
            clientsOptions={contactsOptions}
            client={client}
            onClientChange={handleClientChange}
            disabled={isTimerRunning}
            onStartTimer={handleStartTimer}
            onStopTimer={handleStopTimer}
            descriptionError={descriptionError}
            clientError={clientError}
            activityTypeError={activityTypeError}
            durationError={durationError}
          />
        </Box>
        {dailyListQuery.data && (
          <Box marginY={4}>
            <TimesheetList
              timesheetList={dailyListQuery.data.ListOfDailyTimesheet}
              onDeleteTimesheet={handleDeleteTimesheet}
              getActivityTypeNameById={getActivityTypeNameById}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
