import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { TimesheetDetailsResponse } from "../types";
import { EXAMPLE_RESPONSE_TIMESHEET_DETAILS } from "./example-responses";

export interface UseTimesheetDetailsProps {
  activityId?: string;
  onSuccess?: (data: TimesheetDetailsResponse) => void;
  onError?: (error: string) => void;
}

export const useTimesheetDetails = ({
  activityId,
  onSuccess,
  onError,
}: UseTimesheetDetailsProps = {}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [data, setData] = React.useState<TimesheetDetailsResponse | null>(null);

  const fetchDetails = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    if (process.env.NODE_ENV === "development") {
      setData(EXAMPLE_RESPONSE_TIMESHEET_DETAILS);
      if (onSuccess) onSuccess(EXAMPLE_RESPONSE_TIMESHEET_DETAILS);
      setIsLoading(false);
      return;
    }

    const searchParams = new URLSearchParams();
    if (activityId) {
      searchParams.append("activityId", activityId);
    }

    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v2/en-au/timesheet/get-details-async${
          searchParams.toString() ? `?${searchParams.toString()}` : ""
        }`,
        method: "GET",
        // @ts-ignore
        onSuccess: (response: TimesheetDetailsResponse) => {
          setData(response);
          if (onSuccess) onSuccess(response);
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      setIsError(true);
      const errorMessage = (error as Error).message;
      enqueueSnackbar(errorMessage, { variant: "error" });
      if (onError) onError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, activityId]);

  React.useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  return {
    isLoading,
    isError,
    data,
    refetch: fetchDetails,
  };
};
