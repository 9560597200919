import React from "react";
import {
  DataGrid,
  Column,
  Summary,
  TotalItem,
} from "devextreme-react/cjs/data-grid";
import { formatFinancialNumber } from "../../../utils/format-number";

interface PaymentSummaryDataGridProps {
  paymentDetails: any[];
  date: Date | null;
}

export const PaymentSummaryDataGrid = React.forwardRef<
  DataGrid<any>,
  PaymentSummaryDataGridProps
>(({ paymentDetails, date }, ref) => {
  return (
    <DataGrid
      ref={ref}
      keyExpr="documentNo"
      dataSource={paymentDetails}
      showBorders={false}
      rowAlternationEnabled={true}
      columnAutoWidth={true}
      wordWrapEnabled={true}
      showColumnLines={true}
      showRowLines={true}
      repaintChangesOnly
    >
      <Column
        dataField="documentFromContactCode"
        caption="Client"
        alignment="left"
        allowSearch
      />
      <Column
        dataField="documentNo"
        caption="Payment for"
        alignment="left"
        allowSearch
        calculateCellValue={(rowData: any) => {
          return `${rowData.documentType} ${rowData.documentNo}`;
        }}
      />
      <Column
        dataField="documentDueDate"
        caption="Due"
        alignment="center"
        dataType="date"
        format="dd/MM/yyyy"
      />
      <Column
        dataField="documentDueDate"
        caption="Days"
        name="daysOverdue"
        alignment="center"
        calculateCellValue={(rowData: any) => {
          const dueDate = new Date(rowData.documentDueDate);
          const paymentDate = date || new Date();
          const diffTime = paymentDate.getTime() - dueDate.getTime();
          return Math.floor(diffTime / (1000 * 60 * 60 * 24));
        }}
      />
      <Column
        dataField="documentReceivedAmt"
        caption="Received"
        alignment="right"
        format={{
          type: "fixedPoint",
          precision: 2,
        }}
        allowSearch
      />

      <Summary>
        <TotalItem
          column="documentReceivedAmt"
          summaryType="sum"
          displayFormat="Total received: {0}"
          valueFormat={{
            type: "fixedPoint",
            precision: 2,
          }}
        />
      </Summary>
    </DataGrid>
  );
});
