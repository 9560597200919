import React, { useRef, useState, useEffect } from "react";
import { Box, TextField, Button } from "@mui/material";
import { IconTrash } from "../../../components/icons/trash";
import dayjs from "dayjs";

interface TimesheetTimerProps {
  onStart?: (startTime: dayjs.Dayjs) => void;
  onStop?: (duration: string) => void;
  error?: string | null;
}

const ELEMENT_BORDER_RADIUS = "8px";

const TimesheetTimer: React.FC<TimesheetTimerProps> = ({
  onStart,
  onStop,
  error,
}) => {
  const [status, setStatus] = useState<"idle" | "started">("idle");
  const [time, setTime] = useState("00:00:00");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const secondsRef = useRef(0);

  const startTimer = () => {
    if (intervalRef.current) return;

    if (time === "00:00:00") {
      onStart?.(dayjs());
    }

    const [hours, minutes, seconds] = time.split(":").map(Number);
    secondsRef.current = hours * 3600 + minutes * 60 + seconds;

    intervalRef.current = setInterval(() => {
      secondsRef.current += 1;
      const h = Math.floor(secondsRef.current / 3600);
      const m = Math.floor((secondsRef.current % 3600) / 60);
      const s = secondsRef.current % 60;
      setTime(
        `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s
          .toString()
          .padStart(2, "0")}`,
      );
    }, 1000);
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      onStop?.(time);
    }
  };

  useEffect(() => {
    return () => stopTimer();
  }, []);

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9:]*$/.test(value)) {
      const parts = value.split(":");
      if (parts.length <= 3 && parts.every((part) => part.length <= 2)) {
        setTime(value);
        if (time === "00:00:00") {
          onStart?.(dayjs());
        }
      }
    }
  };

  const handleTimeBlur = () => {
    const [h = "00", m = "00", s = "00"] = time.split(":");
    const formattedTime = `${h.padStart(2, "0")}:${m.padStart(2, "0")}:${s.padStart(2, "0")}`;
    setTime(formattedTime);
    onStop?.(formattedTime);
  };

  const handleDelete = () => {
    stopTimer();
    setTime("00:00:00");
    setStatus("idle");
    secondsRef.current = 0;
  };

  return (
    <Box
      display="flex"
      gap={2}
      width={{ xs: "100%", md: "auto" }}
      flex={{ md: 1 }}
    >
      <TextField
        label="Time"
        size="small"
        fullWidth
        value={time}
        onChange={handleTimeChange}
        onBlur={handleTimeBlur}
        disabled={status === "started"}
        error={!!error}
        helperText={error ?? ""}
        FormHelperTextProps={{
          sx: { color: error ? "error.main" : "inherit" },
        }}
        inputProps={{
          pattern: "[0-9:]*",
          maxLength: 8,
        }}
        sx={{
          minWidth: 120,
          color: error ? "error.main" : "inherit",
          bgcolor: "background.paper",
          borderRadius: ELEMENT_BORDER_RADIUS,
          "& .MuiOutlinedInput-root": {
            borderRadius: ELEMENT_BORDER_RADIUS,
            "& input": {
              color: error ? "error.main" : "currentColor",
            },
          },
        }}
      />

      <Box
        display="flex"
        alignItems="center"
        gap={2}
        width={{ xs: "100%", md: "auto" }}
      >
        <TimesheetTimerButton
          status={status}
          onClick={() => {
            if (status === "idle") {
              setStatus("started");
              startTimer();
            } else {
              setStatus("idle");
              stopTimer();
            }
          }}
        />
        {status === "started" && (
          <Button
            variant="text"
            color="error"
            size="small"
            onClick={handleDelete}
            sx={{
              minWidth: 36,
              height: 36,
              borderRadius: "50%",
              p: 0,
            }}
          >
            <IconTrash color="error" paletteType="main" />
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          size="small"
          sx={{
            minWidth: 36,
            height: 36,
            borderRadius: "50%",
            display: { xs: "flex", md: "none" },
          }}
        >
          ✏️
        </Button>
      </Box>
    </Box>
  );
};

const TimesheetTimerButton = ({
  status = "idle",
  onClick,
}: {
  status: "idle" | "started";
  onClick: () => void;
}) => {
  const getButtonStyles = () => {
    switch (status) {
      case "started":
        return {
          bgcolor: "warning.main",
          "&:hover": {
            bgcolor: "warning.dark",
          },
        };
      default:
        return {
          bgcolor: "success.main",
          "&:hover": {
            bgcolor: "success.dark",
          },
        };
    }
  };

  const getIcon = () => {
    return status === "started" ? "⏹" : "▶";
  };

  return (
    <Button
      variant="contained"
      size="small"
      onClick={onClick}
      sx={{
        minWidth: { md: "36px" },
        width: { xs: "100%", md: "36px" },
        height: { xs: "36px", md: "36px" },
        borderRadius: { xs: 8, md: "50%" },
        ...getButtonStyles(),
      }}
    >
      {getIcon()}
    </Button>
  );
};

export { TimesheetTimer, TimesheetTimerButton };
