import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { createSessionForExternal } from "../utils/reusable";
import envConfig from "../config";
import useApi from "./useApi";
import { useCallback, useMemo } from "react";

const openInNewTabStatic = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const useNavigateInternal = () => {
  const navigate = useNavigate();
  const { apiCall } = useApi();
  const { dbId, lang } = useParams();

  const baseUrl = useMemo(() => `/${dbId}/${lang}`, [dbId, lang]);

  const openLegacyInNewTab = useCallback(
    (partialUrl: string) => {
      const newUrl = `https://${envConfig.correspondingService}${baseUrl}${partialUrl}`;
      createSessionForExternal(newUrl, "127.0.0.2", true, apiCall, dbId);
    },
    [apiCall, dbId, baseUrl],
  );

  const openLegacyInCurrentTab = useCallback(
    (partialUrl: string) => {
      const newUrl = `${window.location.origin}${baseUrl}${partialUrl}`;
      createSessionForExternal(newUrl, "127.0.0.2", false, apiCall, dbId);
    },
    [apiCall, dbId, baseUrl],
  );

  const openInNewTab = useCallback(
    (partialUrl: string) => {
      const newUrl = `${window.location.origin}${baseUrl}${partialUrl}`;
      openInNewTabStatic(newUrl);
    },
    [baseUrl],
  );

  const navigateTo = useCallback(
    (path: string) => {
      navigate(baseUrl + path);
    },
    [navigate, baseUrl],
  );

  return {
    openLegacyInNewTab,
    openLegacyInCurrentTab,
    navigateTo,
    openInNewTab,
    dbId,
    lang,
  };
};
