import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import { PayrollDashboardData } from "../types";
import { EXAMPLE_DASHBOARD_DATA } from "./example-response";

export const usePayrollDashboardData = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: PayrollDashboardData) => void;
  onError?: (error: string) => void;
} = {}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [data, setData] = React.useState<PayrollDashboardData | null>(null);

  const getDashboardData = React.useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    if (process.env.NODE_ENV === "development") {
      setData(EXAMPLE_DASHBOARD_DATA);
      if (onSuccess) onSuccess(EXAMPLE_DASHBOARD_DATA);
      setIsLoading(false);
      return;
    }

    try {
      await apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/get-dashboard-data?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        // @ts-ignore
        onSuccess: (response: PayrollDashboardData) => {
          setData(response);
          if (onSuccess) onSuccess(response);
        },
        // @ts-ignore
        onError: (error: string) => {
          setIsError(true);
          enqueueSnackbar(error, { variant: "error" });
          if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("getDashboardData error", error);
      setIsError(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      if (onError) onError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, enqueueSnackbar, onSuccess, onError]);

  const refetch = React.useCallback(() => {
    return getDashboardData();
  }, [getDashboardData]);

  React.useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return {
    isLoading,
    isError,
    data,
    refetch,
  };
};
