import React from "react";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";

export interface TimesheetInsertPayload {
  acvType_avtID: number;
  acvUser_usrID: number | string;
  acvTimeUnits: number;
  acvDescription: string;
  acvNotes: string;
  acvTaskDate: string;
  acvStartTime: string;
  acvEndTime: string;
  acvInvoice_invID: number;
  acvChargeableYN: "Y" | "N";
  acvContact_cntID: number;
  acvHourlyRate: number;
  acvManualUnitsYN: "Y" | "N";
  acvCharge: number;
  acvOurCost: number;
  acvTask_prdID: number;
  acvSite_dpsID: number;
  acvPayroll_pevID: number;
  acvPaidBreakUnits: number;
  acvUnpaidBreakUnits: number;
  acvDocument_docID: string;
  acvTransferGUID: string;
  acvEntrySource: string;
  acvForActionYN: "Y" | "N";
  acvInvReadyYN: "Y" | "N";
}

export interface TimesheetInsertResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
  NewActivityId: string;
}

export interface UseTimesheetInsertProps {
  onSuccess?: (data: TimesheetInsertResponse) => void;
  onError?: (error: string) => void;
}

export const useTimesheetInsert = ({
  onSuccess,
  onError,
}: UseTimesheetInsertProps = {}) => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const insertTimesheet = React.useCallback(
    async (payload: TimesheetInsertPayload) => {
      console.log("payload", payload);
      setIsLoading(true);
      setIsError(false);

      try {
        await apiCall({
          url: `https://${envConfig.apiDev1Exacc}/api/v2/en-au/timesheet/insert-async?BaseHostURL=${envConfig.mainServiceUrl}`,
          method: "POST",
          // @ts-ignore
          body: payload,
          // @ts-ignore
          onSuccess: (response: TimesheetInsertResponse) => {
            if (onSuccess) onSuccess(response);
          },
          // @ts-ignore
          onError: (error: string) => {
            setIsError(true);
            enqueueSnackbar(error, { variant: "error" });
            if (onError) onError(error);
          },
        });
      } catch (error) {
        setIsError(true);
        const errorMessage = (error as Error).message;
        enqueueSnackbar(errorMessage, { variant: "error" });
        if (onError) onError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall],
  );

  return {
    isLoading,
    isError,
    insertTimesheet,
  };
};
