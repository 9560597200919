import React from "react";
import DataGrid, { Column } from "devextreme-react/cjs/data-grid";
import { useNavigate, useParams } from "react-router-dom";

import { EventListDataRow, PayrollDashboardData } from "../types";
import { getAlignment } from "../../../helpers/table";

interface PayrollDashboardEventsListGridProps {
  data: EventListDataRow[];
  columns: PayrollDashboardData["EventsList"]["ColumnsList"];
  tooltips?: PayrollDashboardData["EventsList"]["TooltipsList"];
  actionsList?: PayrollDashboardData["EventsList"]["ActionsList"];
}

export const PayrollDashboardEventsListGrid: React.FC<
  PayrollDashboardEventsListGridProps
> = ({ data, columns, tooltips, actionsList }) => {
  const navigate = useNavigate();
  const { dbId, lang } = useParams();

  const handleRowClick = (e: any) => {
    if (!e.data) return;

    const { pevID, pevStatus } = e.data;
    const isOpenEvent = pevStatus === "D" || pevStatus === "U";
    const path = isOpenEvent ? "pay-event-open" : "pay-event-finalised";

    navigate(`/${dbId}/${lang}/payroll/${path}/${pevID}`);
  };

  return (
    <DataGrid
      dataSource={data}
      showBorders
      allowColumnResizing
      columnAutoWidth
      rowAlternationEnabled
      onRowClick={handleRowClick}
    >
      {columns.map((col) => (
        <Column
          key={col.RpcID}
          dataField={col.RpcDataSource}
          caption={col.RpcHeader}
          dataType={col.RpcDataType_rpdID === 5 ? "date" : undefined}
          format={col.RpcDataType_rpdID === 5 ? "dd/MM/yyyy" : undefined}
          alignment={getAlignment(col.RpcAlign)}
          minWidth={col.RpcMinWidth}
          width={col.RpcMaxWidth}
          allowSorting={col.RpcSort === "Y"}
          allowFiltering={col.RpcFilter === "Y"}
          allowResizing={col.RpcAllowResizing === "Y"}
          hidingPriority={col.HidingPriority}
          visible={col.RpcVisible === "Y"}
        />
      ))}
    </DataGrid>
  );
};
