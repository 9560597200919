import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ClockIcon } from "@mui/x-date-pickers-pro";
import { TimesheetErrors } from "../../../pages/TimesheetDetailed/TimesheetDetailed";

interface TimesheetTimeProps {
  date: dayjs.Dayjs | null;
  onDateChange: (date: dayjs.Dayjs | null) => void;
  startTime: dayjs.Dayjs | null;
  onStartTimeChange: (time: dayjs.Dayjs | null) => void;
  endTime: dayjs.Dayjs | null;
  onEndTimeChange: (time: dayjs.Dayjs | null) => void;
  timeUnits: number;
  errors: TimesheetErrors;
}

const TimesheetTime: React.FC<TimesheetTimeProps> = ({
  date,
  onDateChange,
  startTime,
  onStartTimeChange,
  endTime,
  onEndTimeChange,
  timeUnits,
  errors,
}) => {
  const [internalStartTime, setInternalStartTime] = useState("00:00:00");
  const [internalEndTime, setInternalEndTime] = useState("00:00:00");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setInternalStartTime(startTime?.format("HH:mm:ss") || "00:00:00");
  }, [startTime]);

  useEffect(() => {
    setInternalEndTime(endTime?.format("HH:mm:ss") || "00:00:00");
  }, [endTime]);

  const handleStartTimeBlur = () => {
    const [h = "00", m = "00", s = "00"] = internalStartTime.split(":");
    const formattedTime = `${h.padStart(2, "0")}:${m.padStart(2, "0")}:${s.padStart(2, "0")}`;
    setInternalStartTime(formattedTime);
    onStartTimeChange(dayjs(formattedTime, "HH:mm:ss"));
  };

  const handleEndTimeBlur = () => {
    if (!internalEndTime) return;
    const [h = "00", m = "00", s = "00"] = internalEndTime.split(":");
    const formattedTime = `${h.padStart(2, "0")}:${m.padStart(2, "0")}:${s.padStart(2, "0")}`;
    setInternalEndTime(formattedTime);
    onEndTimeChange(dayjs(formattedTime, "HH:mm:ss"));
  };

  return (
    <Box>
      <Typography variant="subtitle1" mb={2}>
        Time
      </Typography>

      <Box
        display="flex"
        gap={2}
        flexDirection={isMobile ? "column" : "row"}
        sx={{
          "& > *": {
            width: isMobile ? "100%" : "auto",
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={date}
            onChange={onDateChange}
            slotProps={{
              textField: {
                size: "small",
                label: "Date *",
                error: !!errors.date,
                helperText: errors.date,
              },
            }}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>

        <TextField
          label="Start Time *"
          size="small"
          value={internalStartTime}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[0-9:]*$/.test(value)) {
              const parts = value.split(":");
              if (
                parts.length <= 3 &&
                parts.every((part) => part.length <= 2)
              ) {
                setInternalStartTime(value);
              }
            }
          }}
          onBlur={handleStartTimeBlur}
          error={!!errors.startTime}
          helperText={errors.startTime}
          InputProps={{
            startAdornment: <ClockIcon sx={{ marginRight: 1 }} />,
          }}
          inputProps={{
            pattern: "[0-9:]*",
            maxLength: 8,
          }}
        />

        <TextField
          label="End Time *"
          size="small"
          value={internalEndTime}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[0-9:]*$/.test(value)) {
              const parts = value.split(":");
              if (
                parts.length <= 3 &&
                parts.every((part) => part.length <= 2)
              ) {
                setInternalEndTime(value);
              }
            }
          }}
          onBlur={handleEndTimeBlur}
          error={!!errors.endTime}
          helperText={errors.endTime}
          InputProps={{
            startAdornment: <ClockIcon sx={{ marginRight: 1 }} />,
          }}
          inputProps={{
            pattern: "[0-9:]*",
            maxLength: 8,
          }}
        />

        <TextField
          label="Time Units"
          value={timeUnits}
          size="small"
          InputProps={{ readOnly: true }}
        />
      </Box>
    </Box>
  );
};

export default TimesheetTime;
