import React, { useState, useRef } from 'react';
import { Box, Radio, RadioGroup, FormControlLabel, Button, Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import DateInput from '../DateRangePicker/DateInput';

interface SingleDatePickerProps {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  label?: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

interface Shortcut {
  period: 'Week' | 'Month' | 'Quarter' | 'Year';
  type: 'EndOfThis' | 'EndOfPrevious';
}

const ShortcutBar = ({ onSelect, onToday }: { 
  onSelect: (shortcut: Shortcut) => void;
  onToday: () => void;
}) => {
  const [selectedType, setSelectedType] = useState<'EndOfThis' | 'EndOfPrevious' | null>(null);
  const periods = ['Week', 'Month', 'Quarter', 'Year'];

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <RadioGroup value={selectedType} onChange={(e) => setSelectedType(e.target.value as 'EndOfThis' | 'EndOfPrevious')}>
        <FormControlLabel value="EndOfThis" control={<Radio />} label="End of This" />
        <FormControlLabel value="EndOfPrevious" control={<Radio />} label="End of Previous" />
      </RadioGroup>
      
      <Stack spacing={1}>
        {periods.map((period) => (
          <Button 
            key={period}
            disabled={!selectedType}
            variant="contained"
            onClick={() => onSelect({ 
              period: period as Shortcut['period'], 
              type: selectedType as Shortcut['type'] 
            })}
          >
            {period}
          </Button>
        ))}
      </Stack>

      <Button
        variant="outlined"
        onClick={onToday}
        sx={{ position: 'absolute', right: 8, bottom: 8 }}
      >
        Today
      </Button>
    </Box>
  );
};

export const SingleDatePicker: React.FC<SingleDatePickerProps> = ({
  value,
  onChange,
  label = 'Select Date',
  minDate,
  maxDate
}) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const handleShortcutSelect = ({ period, type }: Shortcut) => {
    const now = dayjs();
    let date: Dayjs;
    const fiscalYear = now.month() > 5 ? now.year() : now.year() - 1; 

    switch(period) {
      case 'Week':
        date = type === 'EndOfThis' ? now.endOf('week') : now.subtract(1, 'week').endOf('week');
        break;
      case 'Month':
        date = type === 'EndOfThis' ? now.endOf('month') : now.subtract(1, 'month').endOf('month');
        break;
      case 'Quarter':
        date = type === 'EndOfThis' ? now.endOf('quarter') : now.subtract(1, 'quarter').endOf('quarter');
        break;
      case 'Year':
        if (type === 'EndOfThis') {
          date = dayjs(`30/06/${fiscalYear}`, 'DD/MM/YYYY');
        } else {
          date = dayjs(`30/06/${fiscalYear - 1}`, 'DD/MM/YYYY');
        }
        break;
    }

    onChange(date);
    setInputValue(date.format('DD/MM/YYYY'));
    setCalendarOpen(false);
  };

  const handleToday = () => {
    const today = dayjs();
    onChange(today);
    setInputValue(today.format('DD/MM/YYYY'));
    setCalendarOpen(false);
  };

  const validateAndSetDate = (value: string) => {
    if (!value) return;
    
    const formats = ['DD/MM/YYYY', 'DD.MM.YYYY', 'DD-MM-YYYY', 'DDMMYYYY'];
    let parsedDate: Dayjs | null = null;

    for (const format of formats) {
      const date = dayjs(value, format, true);
      if (date.isValid()) {
        parsedDate = date;
        break;
      }
    }

    if (parsedDate) {
      onChange(parsedDate);
      setError(false);
      setInputValue(parsedDate.format('DD/MM/YYYY'));
    } else {
      setError(true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Box>
        <DateInput
          label={label}
          value={inputValue}
          onChange={(value) => {
            setInputValue(value);
            if (value.length >= 8) {
              validateAndSetDate(value);
            }
          }}
          error={error}
          onOpenCalendar={() => setCalendarOpen(true)}
          ref={inputRef}
        />

        {calendarOpen && (
        <DatePicker
        open={true}
        onClose={() => setCalendarOpen(false)}
        value={value}
        onChange={(newDate) => {
          if (newDate) {
            onChange(newDate);
            setInputValue(newDate.format('DD/MM/YYYY'));
          } else {
            onChange(null);
            setInputValue('');
          }
        }}
        onYearChange={(newDate) => {
          // Prevent calendar from closing on year selection
          if (newDate) {
            setTimeout(() => {
              inputRef.current?.focus();
            }, 1);
          }
        }}
        slots={{
          actionBar: () => (
            <ShortcutBar
              onSelect={handleShortcutSelect}
              onToday={handleToday}
            />
          ),
        }}
        slotProps={{
          textField: { sx: { display: 'none' } },
          popper: {
            anchorEl: inputRef.current?.parentElement,
            sx: {
              '& .MuiPickersLayout-root': {
                display: 'flex',
                flexDirection: 'row-reverse'
              },
              '.MuiDateCalendar-viewTransitionContainer .MuiYearCalendar-root': {
                maxHeight: '220px'
              },
            },
          },
          actionBar: {
            actions: [], // Hide default action buttons
          },
        }}
      />
        )}
      </Box>
    </LocalizationProvider>
  );
};